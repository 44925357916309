import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    ChangeStatus,
    EBookingStatus,
    ECalendarStatus,
    ESystemStatus,
    IBookableResourceBooking,
    IEvent,
    IOtherSiteEvent
} from "../interfaces/event";
import {
    addDays,
    addMinutes,
    differenceInDays,
    differenceInMinutes, endOfDay,
    endOfWeek,
    format,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isWithinInterval, startOfDay,
    startOfWeek, subDays, subMinutes
} from "date-fns";
import { IProviderProps } from "../interfaces/provider";
import { apiContext } from "./api";
import { otherSiteShiftFormatter, shiftsFormatter } from "../helpers/shifts/shiftsFormatter";
import Swal from "sweetalert2";
import { useTheme } from "@fluentui/react";
import { useIncidentTypes } from "../hooks/indidentType";
import { resourcesContext } from "./resources";
import { EEventStatus } from "../helpers/calendar";
import { toast } from "../helpers/notifications";
import {
    IAvailability,
    IAvantCareEnviromentInfo,
    IBookableResourceRosterValidationResponse,
    IDraftSavedItem,
    IExpandCalendarResponse,
    ILinkedError,
    IOtherSiteWorkOrder,
    IPayrollHourVariation,
    IPayrollHourVariationResponse,
    IPublishShiftsRequestViewModel,
    IResourceData,
    IRosteringBookingViewModel,
    IRosterValidationResponse,
    IServiceResponse,
    ISiteLocation,
    IWorkOrder,
    RosterValidationErrorType
} from "../interfaces/resource";
import { IIncidentType } from "../interfaces/incedentTypes";
import { TView } from "../components/scheduler/Scheduler";
import { authContext } from "./auth";
import { IPayrollApi } from "../interfaces/payroll";
import { IDraft } from "../interfaces/draft";

export enum DraftStatus {
    pending,
    synced,
    edited,
    empty,
    publishing,
    found,
}

export enum EPublishStatus {
    cancelled = 'cancelled',
    no_shift = 'noshift',
    network_error = 'networkerror',
    publish_error = 'publisherror',
    error = 'error',
    success = 'success',
}

export enum EEventToDBMap {
    isJobBidding = 'illumina_openforshiftjobbidding',
    start = 'msdyn_timewindowstart',
    end = 'msdyn_timewindowend',
    description = 'msdyn_workordersummary',
    shiftLength = 'msdyn_totalestimatedduration',
    bookableResourceStatus = 'BookingStatus@odata.bind',
    status = 'msdyn_systemstatus',
}

interface IShiftContext {
    loader: { loading: boolean, message: string }
    getShiftsForCalendarView: () => IEvent[]
    getOtherSiteShiftsForCalendarView: () => IOtherSiteEvent[]
    showOtherSiteShifts: boolean
    toggleShowOtherSiteShifts: () => void
    setCalendarDates: (startDate: Date, type: TView) => void
    startDate: Date,
    endDate: Date,
    moveShift: (shiftId: string, fromResourcesId: string | undefined, toResourceId: string | undefined) => boolean
    getShift: (id: string) => IEvent | undefined
    draftStatus: DraftStatus,
    getResourceData: (id: string) => IResourceData
    checkShiftLocationIsValid: (startDate: Date, endDate: Date, eventId?: string, resourceId?: string, originalResourceId?: string) => EEventStatus
    updateShiftInfo: (shiftId: string, data: Partial<IEvent>) => void
    loadDraft: () => void
    saveDraft: () => void
    publishDraft: () => void
    publishShift: (shiftId: string) => void
    undoShiftMove: () => void
    deleteDraft: () => void
    reloadShifts: () => void
    calculateCost: () => void;
    checkRequiredCharacteristics: (shiftId: string, resourceId: string) => boolean
    defineUserAvailability: (dateStart: Date, dateEnd: Date, cResourceId: string, eventId: string) => IAvailability[]
    checkIsModified: (preferredResourceId: string, resourceId: string) => ChangeStatus
    getResourceShifts: (resourceId: string) => IEvent[];
    getEnvironmentInfo: () => IAvantCareEnviromentInfo;
    checkIfShiftAllowed: (event: IEvent) => void;
    publishSingleShift: (event?: IEvent) => Promise<{ status: EPublishStatus, updatedEvent?: Partial<IEvent> }>;
    patchShift: (shiftId: string, shiftData: Partial<IEvent>) => Promise<any>
    view: TView
    setView: (view: TView) => void
    loadingPayrollData: boolean
    siteLocation?: string
    validated: boolean
}

interface IHistoryStore {
    eventId: string
    resourceId: string
    fromResourceID: string
    calendarStatus: ECalendarStatus
}

const shiftsContext = createContext<IShiftContext>({
    loader: { loading: false, message: '' },
    draftStatus: DraftStatus.empty,
    getShiftsForCalendarView: () => ([]),
    getOtherSiteShiftsForCalendarView: () => ([]),
    showOtherSiteShifts: false,
    toggleShowOtherSiteShifts: () => {
    },
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    setCalendarDates: (startDate: Date, type) => {
    },
    moveShift: () => false,
    getShift: (id: string) => {
        return undefined
    },
    updateShiftInfo: (shiftId: string, data: Partial<IEvent>) => {
    },
    getResourceData: () => { return null as any; },
    getResourceShifts: (resourceId: string) => {
        return [];
    },
    checkShiftLocationIsValid: () => 2,
    loadDraft: () => {
    },
    undoShiftMove: () => {
    },
    saveDraft: () => {
    },
    publishDraft: () => {
    },
    deleteDraft: () => {
    },
    reloadShifts: () => {
    },
    checkRequiredCharacteristics: () => false,
    defineUserAvailability: () => [],
    checkIsModified: () => 0,
    calculateCost: () => {
    },
    getEnvironmentInfo: () => null as any,
    checkIfShiftAllowed: () => {
    },
    publishShift: (shiftId: string) => {
    },
    publishSingleShift: async (event?: IEvent) => ({ status: EPublishStatus.error }),
    patchShift: async (shiftId: string, shiftData: Partial<IEvent>) => {
    },
    view: "resourceTimelineFortnight",
    setView: () => {
    },
    loadingPayrollData: false,
    validated: false
})


const ShiftsProvider = (props: IProviderProps) => {
    const theme = useTheme()
    const [cStartDate, setCStartDate] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
    const [cEndDate, setCEndDate] = useState<Date>(endOfWeek(new Date(), { weekStartsOn: 1 }))
    const [view, setView] = useState<TView>('resourceTimelineFortnight')

    const [loadedDates, setLoadedDates] = useState<boolean>(false)

    const [shiftStore, setShiftStore] = useState<IEvent[]>([])
    const [shiftStoreAccess, setShiftStoreAccess] = useState<{ [key: string]: IEvent }>({})
    const [otherSiteShiftStore, setOtherSiteShiftStore] = useState<IOtherSiteEvent[]>([])
    const [showOtherSiteShifts, setShowOtherSiteShifts] = useState(false)

    const [loader, setLoading] = useState<{ loading: boolean, message: string }>({ loading: false, message: '' })
    const [avantCareEnvInfo, setAvantCareEnvInfo] = useState<IAvantCareEnviromentInfo>()
    const [history, setHistory] = useState<IHistoryStore[]>([])
    const [draftStatus, setDraftStatus] = useState<DraftStatus>(DraftStatus.empty)
    const [draftId, setDraftId] = useState<string | undefined>()
    const [incidentTypes, reloadIncidentTypes] = useIncidentTypes([])
    const { get, post, patch, remove } = useContext(apiContext)
    const [validateHack, setValidationHack] = useState(true)
    //const params = useParams<TUrlParams>()
    const { getResource, getAllResources } = useContext(resourcesContext)
    const [reloadView, setReloadView] = useState(false)
    const { getAccount } = useContext(authContext)
    const [payrollNeedsUpdate, setPayrollNeedsUpdate] = useState(false);
    const [loadingPayrollData, setLoadingPayrollData] = useState(false);
    const [siteLocation, setSiteLocation] = useState<string>();
    const [payrollHoursVariations, setPayrollHoursVariations] = useState<IPayrollHourVariation[]>();
    const [shiftStoreChangeQueue, setShiftStoreChangeQueue] = useState<{ [key: string]: IEvent }>();
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (!loader.loading && payrollNeedsUpdate && !loadingPayrollData) {
            getPayrollData()
        }
    }, [payrollNeedsUpdate, loader])

    useEffect(() => {
        const newShiftStoreAccess: { [key: string]: IEvent } = {};
        shiftStore.forEach(shift => newShiftStoreAccess[shift.id] = shift)
        setShiftStoreAccess(newShiftStoreAccess)
    }, [shiftStore])

    useEffect(() => {
        const loadPayrollCycle = () => {
            const localPayrollData = localStorage.getItem(`illuminance-scheduler-payroll-data_${getSiteId()}`);

            if (localPayrollData) {
                try {
                    const payrollData = JSON.parse(localPayrollData) as { start: Date, end: Date } | undefined;
                    if (payrollData && payrollData['start'] && payrollData['end']) {
                        const payrollLength = differenceInDays(new Date(payrollData['end']), new Date(payrollData['start']));
                        const diffToNow = differenceInDays(new Date(), new Date(payrollData['start']))
                        const daysSinceStartOfPayroll = diffToNow % payrollLength;
                        let payrollStart = new Date()
                        payrollStart.setDate(payrollStart.getDate() - daysSinceStartOfPayroll);
                        payrollStart = startOfDay(payrollStart);

                        let payrollEnd = new Date();
                        payrollEnd.setTime(payrollStart.getTime());
                        payrollEnd.setDate(payrollEnd.getDate() + payrollLength);
                        payrollEnd = endOfDay(payrollEnd);

                        setCStartDate(payrollStart)
                        setCEndDate(payrollEnd)
                        setLoadedDates(true)
                        setView(payrollLength > 7 ? 'resourceTimelineFortnight' : 'resourceTimelineWeek')

                        setPayrollNeedsUpdate(true);

                        return;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            getPayrollData();
        }
        loadPayrollCycle()
    }, [])


    useEffect(() => {
        console.log(cStartDate, cEndDate);
        const checkForDraft = async () => {
            const account = getAccount();
            const siteId = getSiteId();
            const res = await get(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts`)
            const draftList: IDraft[] = res.body
            const calDraft = draftList.find(draft => {
                const startDate = new Date(draft.start)
                return format(startDate, 'yyyy-MM-dd') === format(cStartDate, 'yyyy-MM-dd')
            })
            if (calDraft) {
                setDraftId(calDraft.id)
                setDraftStatus(DraftStatus.found)
            } else {
                setDraftStatus(DraftStatus.empty)
            }
        }
        if (loadedDates) {
            checkForDraft()
        }
    }, [loadedDates, cStartDate, cEndDate])

    useEffect(() => {
        if ((incidentTypes.length > 0 || !shiftStore || shiftStore.length < 1) && loadedDates) {
            loadShifts();
            loadAvantCareEnviroment();
            loadSiteLocation();
        }
    }, [loadedDates])

    useEffect(() => {
        if (reloadView) {
            setReloadView(false)
        }
    }, [reloadView])

    useEffect(() => {
        let cpShiftStoreChangeQueue = { ...shiftStoreChangeQueue }

        if (shiftStoreChangeQueue) {
            const newShiftStore: IEvent[] = shiftStore.map(shift => {
                const newShift = cpShiftStoreChangeQueue[shift.id];

                if (newShift) {
                    delete cpShiftStoreChangeQueue[shift.id];
                    return newShift;
                }

                return shift;
            })

            Object.entries(cpShiftStoreChangeQueue).forEach(([key, shift]) => newShiftStore.push(shift));

            setShiftStore(newShiftStore)
            setShiftStoreChangeQueue(undefined)
        }
    }, [shiftStoreChangeQueue])

    const getPayrollData = async () => {
        setLoadingPayrollData(true);
        try {
            const startDate = format(cStartDate, 'yyyy-MM-dd')
            const endDate = format(cEndDate, 'yyyy-MM-dd')

            const res = await get(
                `/rostering/${getSiteId()}/payrollcycles?start=${startDate}&end=${endDate}`,
            ) as { body?: { startDate: string, endDate: string }[] }

            if (Array.isArray(res.body)) {
                let payroll = res.body.find(payrollPeriod => isAfter(new Date(), new Date(payrollPeriod.startDate)) && isBefore(new Date(), new Date(payrollPeriod.endDate)))

                if (payroll) {
                    const payrollLength = differenceInDays(new Date(payroll.endDate), new Date(payroll.startDate))
                    const payrollStartToNowDiff = differenceInDays(new Date(), new Date(payroll.startDate))
                    const startOfCurrentPayroll = payrollStartToNowDiff % 14

                    let payrollStart = new Date();
                    payrollStart.setDate(payrollStart.getDate() - startOfCurrentPayroll)
                    payrollStart = startOfDay(payrollStart);

                    let payrollEnd = new Date();
                    payrollEnd.setTime(payrollStart.getTime());
                    payrollEnd.setDate(payrollEnd.getDate() + payrollLength);
                    payrollEnd = endOfDay(payrollEnd);

                    const diff = payrollLength
                    setCStartDate(payrollStart)
                    setCEndDate(payrollEnd)
                    setLoadedDates(true)
                    setView(diff > 7 ? 'resourceTimelineFortnight' : 'resourceTimelineWeek')

                    localStorage.setItem(`illuminance-scheduler-payroll-data_${getSiteId()}`, JSON.stringify({
                        start: payrollStart,
                        end: payrollEnd
                    }))
                    setPayrollNeedsUpdate(false);
                } else {
                    // console.log('payroll not found')
                }
            } else {
                // console.log('res.value is not an array')
            }
        } catch (e) {
            console.error(e);
        }
        setLoadingPayrollData(false)
    }

    const loadAvantCareEnviroment = async () => {
        const envInfo = await get('/AvantCare/environment') as IAvantCareEnviromentInfo;
        setAvantCareEnvInfo(envInfo);
    }
    const loadSiteLocation = async () => {
        const locationID = getSiteId();
        const siteLocation = await get(`/Entity/illumina_sitelocation/${locationID}`, { select: 'illumina_name' }) as ISiteLocation;
        document.title = `Roster board - ${siteLocation.illumina_name}`;
        setSiteLocation(siteLocation?.illumina_name)
    }

    const getEnvironmentInfo = () => {
        return avantCareEnvInfo as IAvantCareEnviromentInfo;
    }
    const getSiteId = () => {
        const siteId = localStorage.getItem('siteid');
        const locationID = siteId ?? "c6fe3919-fb8e-ec11-b400-0022489242ae"
        return locationID;
    }

    const loadShifts = async (syncHistory?: boolean, startData: Date = cStartDate, endData: Date = cEndDate) => {
        setValidated(false)
        setLoading({ loading: true, message: syncHistory ? 'Reloading Shifts' : 'Loading Shifts' });
        let apiIncedientTypes: IIncidentType[] = []
        if (syncHistory || (!incidentTypes || incidentTypes.length < 1)) {
            apiIncedientTypes = await reloadIncidentTypes()
        }

        //extra day for timezone issue. This will load extra shifts which will be excluded later with local date filter
        var sdStr = format(subDays(startData, 1), 'yyyy-MM-dd');
        var edStr = format(addDays(endData, 1), 'yyyy-MM-dd');
        var edEndOfTheDay = endOfDay(endData);

        const locationID = getSiteId();
        const shiftsListPromise = get(
            '/Entity/msdyn_workorder',
            {
                select: 'msdyn_name,msdyn_systemstatus,msdyn_timewindowstart,msdyn_timewindowend,msdyn_timefrompromised,msdyn_timetopromised,_msdyn_preferredresource_value,msdyn_workordersummary,msdyn_bookingsummary,msdyn_serviceaccount,msdyn_instructions,illumina_jobtype,msdyn_msdyn_workorder_msdyn_requirementcharacteristic_WorkOrder,illumina_openforshiftjobbidding, msdyn_totalestimatedduration',
                filter: `illumina_servicelocation eq 390950001 and msdyn_systemstatus ne ${ESystemStatus.Canceled} and _illumina_sitelocationfromid_value eq ${locationID} and (msdyn_timewindowstart ge ${sdStr} and msdyn_timewindowstart le ${edStr})`,
                expand: 'msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder($select=name,_bookingstatus_value,_resource_value,modifiedon),msdyn_serviceaccount($select=name,accountnumber,address1_composite),msdyn_primaryincidenttype($select=msdyn_name,msdyn_incidenttypeid)'
            }
        );
        const shiftsOnOtherSitesPromise = get(
            '/Entity/msdyn_workorder',
            {
                select: 'msdyn_name,msdyn_timewindowstart,msdyn_timewindowend,msdyn_totalestimatedduration,msdyn_timefrompromised,msdyn_timetopromised',
                filter: `msdyn_systemstatus ne ${ESystemStatus.Canceled} and _illumina_sitelocationfromid_value ne ${locationID} and (msdyn_timewindowstart ge ${sdStr} and msdyn_timewindowstart le ${edStr})`,
                expand: 'msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder($select=name,_bookingstatus_value,_resource_value)',
            }
        )
        let dateFilter = `(illumina_payrollcycle/illumina_startdate ge '${sdStr}' and illumina_payrollcycle/illumina_startdate le '${edStr}') ` +
            `or (illumina_payrollcycle/illumina_enddate ge '${sdStr}' and illumina_payrollcycle/illumina_enddate le '${edStr}') ` +
            ` or (illumina_payrollcycle/illumina_startdate le ${sdStr} and illumina_payrollcycle/illumina_enddate ge ${edStr})`;

        const payrollHoursVariationsPromise = get('/Entity/illumina_payrollhourvariation', {
            filter: dateFilter,
            select: 'illumina_minimumnoofworkhoursperpayroll,illumina_maximumnoofworkhoursperpayroll,_illumina_payrollcycle_value,_illumina_bookableresource_value',
            expand: 'illumina_payrollcycle($select=illumina_startdate,illumina_enddate)'
        })

        const shiftsList = await shiftsListPromise;
        const shiftsOnOtherSites = await shiftsOnOtherSitesPromise;
        const payrollHoursVariations = await payrollHoursVariationsPromise;
        if (payrollHoursVariations?.value) {
            setPayrollHoursVariations(payrollHoursVariations.value.map((x: IPayrollHourVariationResponse) => {
                return {
                    bookableResourceId: x._illumina_bookableresource_value,
                    maximumHoursPerPayroll: x.illumina_maximumnoofworkhoursperpayroll,
                    minimumHoursPerPayroll: x.illumina_minimumnoofworkhoursperpayroll,
                    payrollCycleId: x._illumina_payrollcycle_value,
                    payrollEnd: new Date(x.illumina_payrollcycle.illumina_enddate),
                    payrollStart: new Date(x.illumina_payrollcycle.illumina_startdate)
                } as IPayrollHourVariation;
            }));
        }

        const workOrders = shiftsList.value as IWorkOrder[];
        if (workOrders) {
            const sanitizedList = workOrders.map((shift: any) => shiftsFormatter(shift, apiIncedientTypes.length < 1 ? incidentTypes : apiIncedientTypes))
                .filter(shift => shift?.start && shift.start >= startData && shift.start <= edEndOfTheDay) as IEvent[]; //exclude any extra items outside of payroll cycle
            if (syncHistory && (history && history.length > 0)) {
                const syncedShifts = syncHistoryToState(sanitizedList, history);
                validateShifts(syncedShifts).then(res => {
                    setShiftStoreChangeQueue(res);

                    setLoading({ loading: false, message: '' });
                })
            } else {
                validateShifts(sanitizedList).then(res => {
                    setShiftStoreChangeQueue(res);

                    setLoading({ loading: false, message: '' });
                })
            }
        }

        const otherSiteWorkOrders = shiftsOnOtherSites.value as IOtherSiteWorkOrder[]
        if (otherSiteWorkOrders) {
            const sanitizedList = otherSiteWorkOrders.map((shift) => otherSiteShiftFormatter(shift))
                .filter(shift => shift != undefined && shift?.start && shift.start >= startData && shift.start <= edEndOfTheDay) as IOtherSiteEvent[] //exclude any extra items outside of payroll cycle

            if (sanitizedList.length > 0) {
                setOtherSiteShiftStore(sanitizedList)
            }
        }

        setLoading({ loading: false, message: '' });

    }

    const moveShift = (shiftId: string, formResourceId?: string, toResourceId?: string): boolean => {
        if (!shiftStore) {
            return false
        }
        const cpShifts = [...shiftStore]
        const index = cpShifts.findIndex(sh => sh.id === shiftId)
        if (index < 0) {
            return false
        }
        if (toResourceId) {
            const newShift = { ...cpShifts[index] };
            const hasRequiredCharacteristics = checkRequiredCharacteristics(shiftId, toResourceId)
            const modifiedStatus = checkIsModified(newShift.originalResourceID, toResourceId)
            const oldCalendarStatus = newShift.calendarStatus

            newShift.resourceId = toResourceId

            newShift.changeStatus = modifiedStatus
            newShift.loading = toResourceId !== '0'

            if (!hasRequiredCharacteristics) {
                newShift.calendarStatus = ECalendarStatus.issue
            } else if (toResourceId === '0') {
                newShift.calendarStatus = ECalendarStatus.scheduled
            } else {
                newShift.calendarStatus = ECalendarStatus.assigned
            }
            if (toResourceId === '0') {
                newShift.calendarStatus = ECalendarStatus.scheduled
            }
            setHistory((prev) => {
                return [...prev, {
                    resourceId: toResourceId,
                    eventId: shiftId,
                    fromResourceID: formResourceId ?? "0",
                    calendarStatus: oldCalendarStatus
                }]
            })
            setDraftStatus(DraftStatus.edited)

            if (toResourceId !== '0') {
                checkIfShiftMoveAllowed(newShift)
            }

            setShiftStoreChangeQueue({ [newShift.id]: newShift })
        }

        return true
    }

    const validateShifts = async (shifts: IEvent[]): Promise<{ [key: string]: IEvent }> => {
        const cpShiftStore = [...shifts]
        let assignedShifts: IRosteringBookingViewModel[] = [];
        let updatedShifts: { [key: string]: IEvent } = {};
        let originalShifts: { [key: string]: IEvent } = {};
        let newShiftStore: { [key: string]: IEvent } = {};

        cpShiftStore.forEach(shift => {
            if (shift.resourceId && shift.resourceId != '' && shift.resourceId != '0') {
                assignedShifts.push({
                    id: shift.id,
                    jobId: shift.id,
                    tempId: '',
                    start: shift.start,
                    end: shift.end,
                    bookableResourceId: shift.resourceId,
                    cost: 0,
                    status: shift.changeStatus,
                    bookingId: shift.BookingId
                })
                updatedShifts[shift.id] = {
                    ...shift,
                    validating: true
                }
                originalShifts[shift.id] = shift;
                return;
            }
            updatedShifts[shift.id] = shift;
        });

        setShiftStoreChangeQueue(updatedShifts)

        if (assignedShifts.length > 0) {
            setLoading({ loading: true, message: 'Validating Shifts' });
            const response = (await post(`/Rostering/validate`, {
                shifts: assignedShifts,
                siteId: localStorage.getItem('siteid')
            })) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;
            if (!response.success) {
                if (response.errors && response.errors[0]) {
                    await toast('error', response.errors[0]);
                }

                setLoading({ loading: false, message: '' });

                return originalShifts
            }
            response.body.forEach(returnedShift => {
                if (originalShifts[returnedShift.id]) {
                    newShiftStore[returnedShift.id] = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
                } else {
                    const newShift = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
                    newShiftStore[newShift.id] = newShift;
                }
            });

            setLoading({ loading: false, message: '' });
        }
        setValidated(true)
        return newShiftStore
    }

    const checkIfShiftMoveAllowed = (shift: IEvent) => {
        const otherShifts = shiftStore.filter(x => x.id != shift.id && shift.resourceId == x?.originalResourceID).map(x => {
            return { jobId: x.id, start: x.start, end: x.end, status: ChangeStatus.Deleted }
        });
        post(`/Rostering/bookableresource/${shift.resourceId}/isshiftAllowed?jobId=${shift?.id}`, otherShifts)
            .then((response: IServiceResponse<IRosterValidationResponse>) => {
                if (response.success && response.body.status == 'Failed') {
                    toast('error', response.body.errors[0].message);
                }
                const validatedShift = populateCalendarStatusBasedOnServiceResponse(shift, response.body.errors, shift.resourceId);
                validatedShift.loading = false
                setShiftStoreChangeQueue({ [validatedShift.id]: validatedShift })
            });
    }

    const getCalendarStatus = (shift: IEvent) => {
        if (shift.resourceId == '0') return ECalendarStatus.scheduled;
        const hasRequiredCharacteristics = checkRequiredCharacteristics(shift.id, shift.resourceId);
        return hasRequiredCharacteristics ? ECalendarStatus.assigned : ECalendarStatus.issue;
    }

    const populateCalendarStatusBasedOnServiceResponse = (shift: IEvent, errors: ILinkedError[], targetResourceId: string | undefined): IEvent => {
        const cpShift = { ...shift }
        if (shift.resourceId != targetResourceId) {
            return shift;
        }

        const shiftErrorMessages: string[] = []
        var hasError = false;
        //if resoure id and target resource id doesn't match, it means the shift has been moved out of target resrouce since the FE was waiting for service call to finish
        errors.forEach((error) => {
            //if (cpShift.calendarStatus === ECalendarStatus.error) return;
            switch (error.errorType) {
                case RosterValidationErrorType.ExceededMaxWorkingHours: {
                    cpShift.exceededMaxWorkingHours = true
                    cpShift.calendarStatus = ECalendarStatus.error
                    hasError = true;
                    break
                }
                case RosterValidationErrorType.ConfiltBooking: {
                    cpShift.calendarStatus = ECalendarStatus.error
                    hasError = true;
                    break
                }
                case RosterValidationErrorType.Unavailable: {
                    cpShift.unavailable = true
                    cpShift.calendarStatus = ECalendarStatus.error
                    hasError = true;
                    break
                }
                case RosterValidationErrorType.Unknown: {
                    cpShift.calendarStatus = ECalendarStatus.error
                    hasError = true;
                    break
                }
                case RosterValidationErrorType.MissingSkills: {
                    cpShift.missingSkills = true
                    if (!hasError)
                        cpShift.calendarStatus = ECalendarStatus.issue
                    break
                }
                case RosterValidationErrorType.OverTime: {
                    cpShift.isOverTime = true
                    if (!hasError)
                        cpShift.calendarStatus = ECalendarStatus.issue
                    break
                }
            }
            shiftErrorMessages.push(error.message)
        })
        cpShift.errors = shiftErrorMessages
        return cpShift

    }


    const getShiftsBetweenDates = (startDate: Date, endDate: Date): IEvent[] => {
        return shiftStore.filter(shift => {
            let withinInterval = false;

            try {
                withinInterval = isWithinInterval(
                    new Date(shift.start),
                    {
                        start: new Date(startDate),
                        end: new Date(endDate)
                    })
            } catch (e) {
                console.error(e);
            }

            return withinInterval;
        })
    }

    const getOtherSiteShiftsBetweenDates = (startDate: Date, endDate: Date): IOtherSiteEvent[] => {
        return otherSiteShiftStore.filter(shift => {
            let withinInterval = false;

            try {
                withinInterval = isWithinInterval(
                    new Date(shift.start),
                    {
                        start: new Date(startDate),
                        end: new Date(endDate)
                    })
            } catch (e) {
                console.error(e);
            }

            return withinInterval;
        })
    }

    const getShiftsForView = (): IEvent[] => {
        if (loader?.loading) {
            return []
        }
        return getShiftsBetweenDates(cStartDate, cEndDate)
    }

    const getOtherSiteShiftsForView = (): IOtherSiteEvent[] => {
        if (loader?.loading) {
            return []
        }
        return getOtherSiteShiftsBetweenDates(cStartDate, cEndDate)
    }

    // const getShift = (id: string) => {
    //     const shift = shiftStore.findIndex((shift) => shift.id === id)
    //     return shift > -1 ? shiftStore[shift] : undefined
    // }

    const getShift = useCallback((id: string) => {
        return shiftStoreAccess[id];
    }, [shiftStoreAccess])

    const validationTest = async () => {
        /*
        let error = false
        let newShiftStore: { [key: string]: IEvent } = {};
        shiftStore.forEach((shift) => {
            if (shift.resourceId === '0') {
                return;
            }

            let errors: string[] = []

            if (!checkRequiredCharacteristics(shift.id, shift.resourceId)) {
                errors.push('Resource has a Missing Characteristic');
                error = true
            }
            newShiftStore[shift.id] = {
                ...shift,
                calendarStatus: errors.length > 0 ? ECalendarStatus.error : ECalendarStatus.assigned,
                errors
            }
        })

        if (error) {
            let errorMessage = '';
            setShiftStoreChangeQueue(newShiftStore)
            for (const [key, value] of Object.entries(newShiftStore)) {
                if (value.errors?.length) {
                    errorMessage = `Job ${value.title}- ${value.errors[0]}`;
                    break;
                }
            }
            toast('error', errorMessage);
            return
        }
        */

        const confirmation = await Swal.fire({
            title: 'Are you sure you want to publish the schedule?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.themePrimary,
            cancelButtonColor: theme.palette.tealLight,
            confirmButtonText: 'Publish'
        })
        if (confirmation.isConfirmed) {
            const status = await publish()

            if (status) {
                const finalShifts: { [key: string]: IEvent } = {};
                shiftStore.forEach((shift) => {
                    if (shift.bookableResourceStatus !== EBookingStatus.Scheduled || shift.changeStatus !== ChangeStatus.UnChanged) {
                        finalShifts[shift.id] = {
                            ...shift,
                            bookableResourceStatus: EBookingStatus.Scheduled,
                            changeStatus: ChangeStatus.UnChanged
                        }
                    }
                })
                setShiftStoreChangeQueue(finalShifts)
                toast("success", 'Schedule was published successfully');
            }
        }


    }

    // Doesn't have a way of updating non-move/date changes (e.g. description)
    const publishSingleShift = async (shift?: IEvent): Promise<{ status: EPublishStatus, updatedEventDetails?: Partial<IEvent> }> => {
        if (!shift) {
            return { status: EPublishStatus.no_shift };
        }
        try {

            const confirmation = await Swal.fire({
                title: 'Are you sure you want to publish this shift?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.palette.themePrimary,
                cancelButtonColor: theme.palette.tealLight,
                confirmButtonText: 'Publish'
            })

            if (!confirmation.isConfirmed) return { status: EPublishStatus.cancelled };

            const shiftData: IRosteringBookingViewModel = {
                id: shift.id,
                jobId: shift.id,
                tempId: '',
                start: shift.start,
                end: shift.end,
                bookableResourceId: shift.resourceId === '0' ? '' : shift.resourceId,
                cost: 0,
                status: ChangeStatus.Modified,
                bookingId: shift.BookingId,
            };

            const data: IPublishShiftsRequestViewModel = {
                shifts: [shiftData],
                saveShiftsWarning: true,
                siteId: localStorage.getItem('siteid') ?? '',
            };

            const response = await post(
                `/Rostering/publish`,
                data
            ) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;


            if (response.success && response.body?.length > 0) {
                response.body.forEach(responseShift => {
                    const storedShift = shiftStore?.find(element => element.id === responseShift.id);
                    if (storedShift) {
                        populateCalendarStatusBasedOnServiceResponse(storedShift, responseShift.errors, storedShift.resourceId);
                    }
                });
                let errors = response.body.filter(b => b.errors?.length > 0).map(b => b.errors[0]);
                let errorMessages = errors.filter(e => e.message?.length > 0).map(e => e.message);

                toast('warning', 'Shift was not published. ' + errorMessages)

                return { status: EPublishStatus.publish_error };
            } else if (response.success) {
                const partialShift: Partial<IEvent> = {
                    ...shift,
                    originalResourceID: shift.resourceId,
                    changeStatus: ChangeStatus.UnChanged,
                }

                updateShiftInfo(shift.id, partialShift)
                toast('success', 'Shift was published successfully!')
                return { status: EPublishStatus.success, updatedEventDetails: partialShift };
            } else {
                toast('error', 'Shift was not published. There was an error whilst trying to publish')
                return { status: EPublishStatus.publish_error }
            }
        } catch (err) {
            console.error(err);
            toast('error', 'Shift not published: Network Error')
            return { status: EPublishStatus.network_error };
        }
    }


    // Doesn't have a way of updating non-move/date changes (e.g. description)
    //If not shifts provided, all shifts will be processed
    const publish = async (shifts?: IEvent[]): Promise<boolean> => {
        const prevDraftStatus = draftStatus;
        setDraftStatus(DraftStatus.publishing)

        if (shifts == null)
            shifts = shiftStore;

        const modifiedShifts: IRosteringBookingViewModel[] = [];
        shifts.forEach((shift) => {
            if (shift.changeStatus === ChangeStatus.Modified) {
                const shiftPublishData: IRosteringBookingViewModel = {
                    id: shift.id,
                    jobId: shift.id, //please provide job id and id the same
                    tempId: '', //for future usage. can be used for new shifts
                    start: shift.start,
                    end: shift.end,
                    bookableResourceId: shift.resourceId == '0' ? '' : shift.resourceId, //if unassigned set to empty string
                    cost: 0,
                    status: ChangeStatus.Modified, //For now, use 'modified' but in future, if we allow to add shifts from UI, then we can different status (like added, deleted et.) in here
                    bookingId: shift.BookingId
                }

                modifiedShifts.push(shiftPublishData);
            }
        })
        if (modifiedShifts.length < 1) {
            toast("warning", "No Shifts to Publish")
            setDraftStatus(prevDraftStatus)
            return false;
        }
        const response = (await post(`/Rostering/publish`, {
            shifts: modifiedShifts,
            saveShiftsWarning: true,
            siteId: localStorage.getItem('siteid')
        } as IPublishShiftsRequestViewModel)) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;
        if (!response.success) {
            toast('error', response.errors[0]);
            return false;
        }

        if (response.body.length > 0) {
            response.body.forEach(rb => {
                const shift = shifts?.find(sh => sh.id === rb.id) as IEvent;
                populateCalendarStatusBasedOnServiceResponse(shift, rb.errors, shift.resourceId);
            });
            toast("warning", 'Schedule was not published. There were issues during validation.');
            setDraftStatus(prevDraftStatus)
            return false;
        } else {
            deleteDraft()
            setHistory([])
            setDraftStatus(DraftStatus.empty)
            return true;
        }
    }

    const undoMove = () => {
        const cpHistory = [...history]
        if (cpHistory && cpHistory.length > 0) {
            const editHistory = cpHistory.pop()
            if (editHistory) {
                const cpShiftStore = [...shiftStore]
                const index = cpShiftStore.findIndex((shift) => shift.id === editHistory.eventId)
                if (index > -1) {
                    const shift = cpShiftStore[index];
                    shift.resourceId = editHistory.fromResourceID;
                    shift.changeStatus = shift.originalResourceID != shift.resourceId ? ChangeStatus.Modified : ChangeStatus.UnChanged;
                    shift.displayCost = shift.changeStatus == ChangeStatus.UnChanged ? shift.actualCost : null;
                    shift.calendarStatus = editHistory.calendarStatus
                }
                setShiftStore(cpShiftStore)
                setHistory(cpHistory)
            }
        }
    }

    const deleteDraft = async () => {
        const storageKey = format(cStartDate, 'yyyy-MM-dd')
        localStorage.removeItem(storageKey)

        const start = format(cStartDate, 'yyyy-MM-dd');
        const end = format(cEndDate, 'yyyy-MM-dd');
        const draftKey = `${start}_${end}`;
        const existingDraftIds = draftId;
        let draftIds = existingDraftIds ? existingDraftIds.split(',') : [];
        if (draftIds.length > 0) {
            const account = getAccount();
            const siteId = getSiteId();

            const response = (await remove(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts?draftIds=${draftIds.join(',')}`)) as IServiceResponse<boolean>
            if (response.success)
                localStorage.removeItem(draftKey);
        }

        loadShifts()
        setDraftStatus(DraftStatus.empty)
    }

    const loadDraft = async () => {
        const start = format(cStartDate, 'yyyy-MM-dd');
        const end = format(cEndDate, 'yyyy-MM-dd');
        const siteId = getSiteId();
        const draftKey = `${start}_${end}_${siteId}`;

        const account = getAccount();
        const response = (await get(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts/${draftId}`)) as { history: IHistoryStore[], shiftStore: IEvent[] }
        setHistory(response.history);

        const cpShifts = [...shiftStore];
        const finalShifts: { [key: string]: IEvent } = {};

        response.shiftStore.forEach(shift => {
            finalShifts[shift.id] = {
                ...shift,
                start: new Date(shift.start),
                end: new Date(shift.end)
            }
        })

        cpShifts.forEach(shift => {
            const updatedShift = finalShifts[shift.id];
            if (!updatedShift) {
                finalShifts[shift.id] = {
                    ...shift,
                    resourceId: '0'
                }
            }
        })

        setShiftStoreChangeQueue(finalShifts)

        // const storageKey = format(cStartDate, 'yyyy-MM-dd')
        // const storedHistory = JSON.parse(localStorage.getItem(storageKey) ?? "[]")
        // const cpHistory = [...storedHistory, ...history]
        // setShiftStore(syncHistoryToState(shiftStore, cpHistory))
        // setDraftStatus(DraftStatus.synced)
        // setHistory(cpHistory)
    }

    const syncHistoryToState = (shifts: IEvent[] = shiftStore, funHistory: IHistoryStore[] = history) => {
        const cpShifts = [...shifts]
        return cpShifts.map((shift): IEvent => {
            const historyToProcess = funHistory.find((his) => (his.eventId === shift.id) && (shift.resourceId === "0"))
            const resourceId = historyToProcess ? historyToProcess.resourceId : shift.resourceId;
            return {
                ...shift,
                resourceId: resourceId,
                BookingId: shift.BookingId,
                calendarStatus: getCalendarStatus(shift),
                changeStatus: shift.originalResourceID != resourceId ? ChangeStatus.Modified : ChangeStatus.UnChanged,
            }
        })
    }

    const reloadShifts = async () => {
        await loadShifts(true)
    }

    const saveDraft = async (): Promise<boolean> => {
        if (Array.isArray(history) && history.length > 0) {
            setDraftStatus(DraftStatus.synced)
            // const storageKey = format(cStartDate, 'yyyy-MM-dd')
            // localStorage.setItem(storageKey, JSON.stringify(history))
            const account = getAccount();
            const siteId = getSiteId();
            const start = format(cStartDate, 'yyyy-MM-dd');
            const end = format(cEndDate, 'yyyy-MM-dd');
            const formData = new FormData();

            const data = {
                history,
                shiftStore
            }

            formData.append("file", JSON.stringify(data));
            formData.append("title", `${start}_${end}`); //TODO: Ask user for the name of the draft
            formData.append("start", start);
            formData.append("end", end);
            const response = (await post(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts`, formData)) as IServiceResponse<IDraftSavedItem>
            if (response.success) {
                const draftKey = `${start}_${end}_${siteId}`;
                // const existingDraftIds = localStorage.getItem(draftKey);
                // let draftIds = existingDraftIds ? existingDraftIds.split(',') : [];
                // draftIds.push(response.body.id);
                // localStorage.setItem(draftKey, draftIds.join(','));
                localStorage.setItem(draftKey, response.body.id);
                setDraftId(response.body.id)
            }
            return response?.success
        } else {
            toast('error', 'Unable to save draft as no changes have been made');
        }
        return false
    }

    const getShiftsInViewForResource = (startDate: Date, endDate: Date, resourceId?: string): IEvent[] => {
        return shiftStore.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let withinInterval = false;
            try {
                withinInterval = isWithinInterval(
                    new Date(shift.start),
                    {
                        start: new Date(startDate),
                        end: new Date(endDate)
                    })
            } catch (e) {
                console.error(e);
            }

            return foundResource && withinInterval
        })
    }

    const getOtherSiteShiftsForResource = (startDate: Date, endDate: Date, resourceId?: string): IOtherSiteEvent[] => {
        return otherSiteShiftStore.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let withinInterval = false;
            try {
                withinInterval = isWithinInterval(
                    new Date(shift.start),
                    {
                        start: new Date(startDate),
                        end: new Date(endDate)
                    })
            } catch (e) {
                console.error(e);
            }

            return foundResource && withinInterval
        })
    }

    const checkResourceForTimeClash = (resourceId: string, startDate: Date, endDate: Date) => {
        //take one mins on/off to allow shifts like 8am-4pm and then 4pm-8pm etc.
        startDate = addMinutes(startDate, 1);
        endDate = subMinutes(endDate, 1);
        return shiftStore.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let within = false;
            try {
                within =
                    isWithinInterval(startDate, { start: shift.start, end: shift.end })
                    ||
                    isWithinInterval(endDate, { start: shift.start, end: shift.end })
                    ||
                    isWithinInterval(shift.start, { start: startDate, end: endDate })
                    ||
                    isWithinInterval(shift.end, { start: startDate, end: endDate })
            } catch (e) {
                console.error(e)
            }

            return foundResource && within
        })
    }

    const checkShiftLocationIsValid = (startDate: Date, endDate: Date, eventId?: string, resourceId?: string, originalResourceId?: string): EEventStatus => {
        if (!resourceId || resourceId === '0' || resourceId === originalResourceId || !eventId) {
            return EEventStatus.valid
        }
        let valid = EEventStatus.error


        const resourcesShifts = checkResourceForTimeClash(resourceId, startDate, endDate);
        const bookableResource = getResource(resourceId);
        if (!bookableResource || !bookableResource.availabilities) {
            return valid = EEventStatus.error
        }
        const isAvailable = bookableResource.availabilities.find(x => x.timeCode == 'Available' && x.start <= startDate && x.end >= endDate) != null
        if (!isAvailable) return EEventStatus.error;

        if ((!resourcesShifts || resourcesShifts.length < 1)) {
            valid = EEventStatus.valid
        }
        if (valid === EEventStatus.valid) {
            valid = checkRequiredCharacteristics(eventId, resourceId) ? EEventStatus.valid : EEventStatus.characteristicsIssue
        }
        return valid;
    }

    const checkRequiredCharacteristics = (shiftId: string, resourceId: string): boolean => {
        const shift = getShift(shiftId);

        if ((shift?.incidentType.characteristic.length ?? -1) === 0) {
            return true;
        }

        const resource = getResource(resourceId)
        const characteristicsMet = !!shift?.incidentType.characteristic.every(incidentCharacteristic =>
            resource?.characteristics.find(resourceCharacteristic =>
                resourceCharacteristic.name === incidentCharacteristic.name
            )
        )

        return characteristicsMet;
    }

    const checkIsModified = (originalResourceID: string, resourceId: string) => {
        if (!originalResourceID || originalResourceID === "0") {
            if (resourceId === "0") {
                return ChangeStatus.UnChanged
            } else {
                return ChangeStatus.Modified
            }
        } else {
            if (originalResourceID === resourceId) {
                return ChangeStatus.UnChanged
            } else {
                return ChangeStatus.Modified
            }
        }
    }

    const getResourcesData = (resourceId: string) => {
        let resource = getResource(resourceId);
        const resourcesShifts = getShiftsInViewForResource(cStartDate, cEndDate, resourceId)
        const otherSiteResourceShifts = getOtherSiteShiftsForResource(cStartDate, cEndDate, resourceId)
        const shiftsCount = resourcesShifts.length
        const allSitesShiftsCount = resourcesShifts.length + otherSiteResourceShifts.length
        let hoursWorked = 0;
        let totalPay = 0;
        resourcesShifts.forEach((shift) => {
            hoursWorked = hoursWorked + differenceInMinutes(
                new Date(shift.end) ?? new Date(),
                new Date(shift.start) ?? new Date(),
            );
            if (shift.displayCost)
                totalPay += shift.displayCost;
        })

        let allSitesHoursWorked = hoursWorked;
        otherSiteResourceShifts.forEach((shift) => {
            allSitesHoursWorked = allSitesHoursWorked + differenceInMinutes(
                new Date(shift.end) ?? new Date(),
                new Date(shift.start) ?? new Date(),
            );
        });

        let payrollVariation = payrollHoursVariations?.find(x => x.bookableResourceId.toLocaleLowerCase() == resourceId.toLocaleLowerCase() && isSameDay(x.payrollStart, cStartDate) && isSameDay(x.payrollEnd, cEndDate))

        return {
            maxHoursAllowedPerPayroll: payrollVariation && payrollVariation.maximumHoursPerPayroll ? payrollVariation.maximumHoursPerPayroll : resource?.maximumWorkHourPerPayroll,
            maxShiftsAllowedPerPayroll: resource?.maximumWorkHourPerPayroll,
            maxHoursPerShift: resource?.maxHoursPerShift,
            hoursWorked: hoursWorked / 60,
            allSitesHoursWorked: allSitesHoursWorked / 60,
            shifts: shiftsCount,
            allSitesShifts: allSitesShiftsCount,
            requirements: [],
            totalPay,
            minimumWorkHoursPerPayroll: payrollVariation && payrollVariation.minimumHoursPerPayroll ? payrollVariation.minimumHoursPerPayroll : resource?.minimumWorkHoursPerPayroll,
        } as IResourceData
    }
    const getResourceShifts = (resourceId: string) => {
        return shiftStore.filter(x => x.resourceId === resourceId);
    }

    const setCalendarDates = (startDate: Date, type: TView) => {
        let endDate: Date
        if (type === "resourceTimelineFortnight") {
            endDate = new Date(startDate.getTime())
            endDate.setDate(endDate.getDate() + 13)
            endDate = endOfDay(endDate);
        } else {
            endDate = new Date(startDate.getTime())
            endDate.setDate(endDate.getDate() + 6)
            endDate = endOfDay(endDate);
        }
        if (isEqual(cStartDate, startDate) && isEqual(cEndDate, endDate)) {
            console.error("No Update Required")
            return
        }
        setCEndDate(endDate)
        setCStartDate(startDate)
        loadShifts(true, startDate, endDate).then(() => {
            setHistory([])
        });




    }

    const updateShiftInfo = (shiftId: string, data: Partial<IEvent>) => {
        const cpShifts = [...shiftStore]
        const updatedShiftIndex = cpShifts.findIndex(shift => shift.id === shiftId)

        if (updatedShiftIndex < 0) {
            return
        }

        setShiftStoreChangeQueue({ [shiftId]: { ...cpShifts[updatedShiftIndex], ...data, loading: false } })

        return cpShifts[updatedShiftIndex];
    }

    const defineUserAvailability = (startDate: Date, endDate: Date, cResourceId: string, eventId: string): IAvailability[] => {
        const resources = getAllResources()
        return resources.map((resource) => {
            if (cResourceId === resource.id) {
                if (resource.availabilities) {
                    const available = resource.availabilities?.find(x => x.timeCode == 'Available' && x.start >= startDate && endDate <= x.end);
                    return { resourceId: resource.id, availability: available ? EEventStatus.valid : EEventStatus.error }
                }

            }
            const availability = checkShiftLocationIsValid(startDate, endDate, eventId, resource.id);
            return { resourceId: resource.id, availability }
        })

    }

    const callbackShifts = useCallback(() => {
        return getShiftsForView()
    }, [shiftStore, getShiftsForView, cEndDate, cStartDate, draftStatus, loader])

    const memoCallbackShifts = useMemo(callbackShifts, [callbackShifts])

    const callbackOtherSiteShifts = useCallback(() => {
        return getOtherSiteShiftsForView()
    }, [otherSiteShiftStore, getOtherSiteShiftsForView, cEndDate, cStartDate, loader])

    const memoCallbackOtherSiteShifts = useMemo(callbackOtherSiteShifts, [callbackOtherSiteShifts])

    const calculateCost = async () => {
        setLoading({ loading: true, message: 'Calculating cost...' });
        const cpShifts = [...shiftStore]
        const shifts = shiftStore.filter(x => x.resourceId != '0' && x.BookingId != '').map<Partial<IRosteringBookingViewModel>>(x => {
            return { bookableResourceId: x.resourceId, end: x.end, start: x.start, id: x.id, }
        });
        const calculatedShiftResponse: IServiceResponse<IRosteringBookingViewModel[]> = await post('/Rostering/calclatecosts', shifts);
        if (!calculatedShiftResponse.success) {
            //TODO: error handling;
            if (loader.message === 'Calculating cost...') {
                setLoading({ loading: false, message: '' });
            }
            return;
        }
        const calculatedShifts = calculatedShiftResponse.body;
        shiftStore.forEach(s => {
            const cs = calculatedShifts.find(x => x.id == s.id);
            if (cs) {
                s.actualCost = cs.cost;
                s.displayCost = cs.cost;
            }
        });

        setShiftStore(cpShifts)

        if (loader.message === 'Calculating cost...') {
            setLoading({ loading: false, message: '' });
        }
        return true;
    };

    const checkIfShiftAllowed = async (event: IEvent) => {
        if (!event.bidders) {
            return
        }
        const bidders: IBookableResourceBooking[] = []

        for (const bidder of event.bidders) {
            const assignedShifts = getResourceShifts(bidder.bookableResourceId);
            const response = (await post(`/Rostering/bookableresource/${bidder.bookableResourceId}/isshiftAllowed?jobId=${event?.id}`, assignedShifts)) as IServiceResponse<IRosterValidationResponse>;
            if (response.success && response.body.status == 'Failed') {
                toast('error', response.body.errors[0].message);
                continue;
            }
            const errors = response.body.errors
            errors.forEach((error) => {
                switch (error.errorType) {
                    case RosterValidationErrorType.OverTime: {
                        bidder.isOverTime = true
                        break
                    }
                    case RosterValidationErrorType.MissingSkills: {
                        bidder.missingSkills = true
                        break
                    }
                    case RosterValidationErrorType.Unavailable: {
                        bidder.unavailable = true
                        break
                    }
                    case RosterValidationErrorType.ExceededMaxWorkingHours: {
                        bidder.exceededMaxWorkingHours = true
                    }
                }
            })
            bidders.push(bidder);
        }

        updateShiftInfo(event.id, { bidders })
    }
    const publishShift = async (shiftId: string) => {
        let event = shiftStore.find(x => x.id == shiftId) as IEvent;


        const confirmation = await Swal.fire({
            title: 'Are you sure you want to publish the shift?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.themePrimary,
            cancelButtonColor: theme.palette.tealLight,
            confirmButtonText: 'Publish shift'
        })
        if (confirmation.isConfirmed) {
            let success = await publish([event]);
            if (!success) return;
            event.bookableResourceStatus = EBookingStatus.Scheduled;
            event.changeStatus = ChangeStatus.UnChanged;
            updateShiftInfo(shiftId, event);
        }

    }

    const patchShift = async (shiftId: string, shiftData: Partial<IEvent>) => {
        var workOrderData = {};
        var bookableResourceData = {};

        Object.entries(shiftData).forEach(keyPair => {
            if (!(Object.keys(EEventToDBMap).includes(keyPair[0]))) return;

            if (keyPair[0] === 'bookableResourceStatus') {
                bookableResourceData = {
                    ...bookableResourceData,
                    [EEventToDBMap[keyPair[0] as 'bookableResourceStatus']]: `/bookingstatuses(${keyPair[1]})`,
                    duration: shiftData.shiftLength
                }

                return;
            }

            workOrderData = {
                ...workOrderData,
                [EEventToDBMap[keyPair[0] as 'isJobBidding' | 'start' | 'end' | 'description' | 'shiftLength']]: keyPair[1],
            }
        })

        var workOrderResponse;
        var bookableResourceResponse;

        if (Object.entries(workOrderData).length) {
            workOrderResponse = patch('/Entity/msdyn_workorder/' + shiftId, workOrderData);
        }

        if (Object.entries(bookableResourceData).length) {
            const shift = shiftStore.find(event => event.id === shiftId)

            bookableResourceResponse = patch('/Entity/bookableresourcebooking/' + shift?.BookingId, bookableResourceData);

            const cpShifts = [...shiftStore];
            const shiftIndex = cpShifts.findIndex((event) => event.id === shiftId)
            setShiftStore([...shiftStore.slice(0, shiftIndex), ...shiftStore.slice(shiftIndex + 1)])
        }

        await workOrderResponse;
        await bookableResourceResponse;

        reloadShifts();
    }

    const toggleShowOtherSiteShifts = useCallback(() => {
        setShowOtherSiteShifts(!showOtherSiteShifts)
    }, [showOtherSiteShifts, setShowOtherSiteShifts])

    return (
        <shiftsContext.Provider value={{
            saveDraft,
            startDate: cStartDate,
            endDate: cEndDate,
            loader,
            moveShift,
            getShiftsForCalendarView: () => memoCallbackShifts,
            getOtherSiteShiftsForCalendarView: () => memoCallbackOtherSiteShifts,
            getResourceData: getResourcesData,
            checkShiftLocationIsValid,
            updateShiftInfo,
            getShift,
            draftStatus,
            setCalendarDates,
            undoShiftMove: undoMove,
            publishDraft: validationTest,
            deleteDraft,
            loadDraft,
            reloadShifts,
            checkRequiredCharacteristics,
            defineUserAvailability,
            checkIsModified,
            calculateCost,
            getResourceShifts,
            getEnvironmentInfo,
            checkIfShiftAllowed,
            publishShift,
            publishSingleShift,
            patchShift,
            showOtherSiteShifts,
            toggleShowOtherSiteShifts,
            view,
            setView,
            loadingPayrollData,
            siteLocation,
            validated
        }}>
            {props.children}
        </shiftsContext.Provider>
    )

}

// function getRandomInt(max: number) {
//     return Math.floor(Math.random() * max);
// }

export { ShiftsProvider, shiftsContext }
