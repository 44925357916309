import {ColCellContentArg, ColSpec} from "@fullcalendar/resource-common";
import {ResourceRender} from "../Resources/ResourceRender";
import { shiftsContext} from "../../../providers/shifts";
import React, {useContext, useState} from "react";

const Columns = (): ColSpec[] => {

    const {getResourceData, showOtherSiteShifts} = useContext(shiftsContext)
    return [
        {
            field: 'title',
            headerContent: () => (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span>Resource</span>
                </div>
            ),
            cellContent: (content: ColCellContentArg) => {
                if (!content.resource) {
                    return <></>
                }
                return (
                    <ResourceRender resource={content.resource} view={content.view}/>
                )
            }
        },
        {
            field: 'Shift Details',
            headerContent: () => (
                <div style={{display: 'flex', alignItems: 'center',}}>
                    <span>Shift Details</span>
                </div>
            ),
            cellContent: (content: ColCellContentArg) => {
                if (!content.resource || !content.resource.id) {
                    return <></>
                }
                const userData = getResourceData(content.resource.id) 
                const isUndefined = content.resource.id === '0';
                const minHoursMet = userData.minimumWorkHoursPerPayroll && userData.hoursWorked >= userData.minimumWorkHoursPerPayroll;

                const maxHoursThreshold = userData.maxHoursAllowedPerPayroll //content.view.type === 'resourceTimelineWeek' ? 24 : 48
                //const maxShiftsThreshold = content.view.type === 'resourceTimelineWeek' ? 5 : 10

                return (
                    <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>

                        {!isUndefined &&
                            <>
                                <p><strong
                                    style={{color: userData.allSitesHoursWorked > maxHoursThreshold ? "red" : "black"}}>Hours: </strong>
                                    <span style={{color: userData.hoursWorked > maxHoursThreshold ? "red" : "black"}}>
                                            {Number.isInteger(userData.hoursWorked) ? userData.hoursWorked : userData.hoursWorked.toFixed(2)}
                                        </span>
                                    {showOtherSiteShifts &&
                                        <>
                                            &nbsp;
                                            <span
                                                style={{color: userData.allSitesHoursWorked > maxHoursThreshold ? "red" : "black"}}>
                                            ({Number.isInteger(userData.allSitesHoursWorked) ? userData.allSitesHoursWorked : userData.allSitesHoursWorked.toFixed(2)})
                                            </span>
                                        </>
                                    }
                                    <br/>
                                    <strong
                                        //style={{color: userData.shifts > maxShiftsThreshold ? "red" : "black"}} //Commenting this out as max no shift is not a constant
                                        > Total Shifts: </strong>{userData.shifts}&nbsp;{showOtherSiteShifts ? `(${userData.allSitesShifts})` : ''} </p>
                            </>
                        }

                        {minHoursMet == false &&
                            <>
                                <span>
                                    Minimum {userData.minimumWorkHoursPerPayroll} hours contract ({userData.hoursWorked} hours booked)
                                </span>
                            </>
                        }

                    </div>
                )
            }
        }
    ]
}

export {Columns}