import { DefaultButton, IContextualMenuProps, PrimaryButton } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faFloppyDisk, faRotateLeft, faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { DraftStatus, shiftsContext } from "../../providers/shifts";

const DraftControl = () => {
    const { draftStatus, deleteDraft, saveDraft, loadDraft, undoShiftMove, publishDraft, reloadShifts,calculateCost } = useContext(shiftsContext)

    const renderDraftButton = () => {
        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: 'saveDraft',
                    text: 'Save Draft',
                    onClick: saveDraft,
                    disabled: draftStatus !== DraftStatus.empty && draftStatus !== DraftStatus.edited
                },
                {
                    key: 'loadDraft',
                    text: 'Load Draft',
                    onClick: loadDraft,
                    disabled: draftStatus === DraftStatus.empty
                },
                {
                    key: 'discardDraft',
                    text: 'Discard Draft',
                    onClick: deleteDraft,
                    disabled: draftStatus === DraftStatus.empty
                }
            ]
        }

        return <DefaultButton menuProps={menuProps}>Drafts</DefaultButton>
    }

    return (
        <div style={{ display: "inline-flex", gap: '5px' }}>
            {/*<DefaultButton onClick={calculateCost}>*/}
            {/*    Estimate Cost*/}
            {/*</DefaultButton>*/}
            {renderDraftButton()}
            <PrimaryButton onClick={publishDraft}>
                Validate
            </PrimaryButton>
            <DefaultButton onClick={undoShiftMove} title="Undo">
                <FontAwesomeIcon icon={faRotateLeft} />
            </DefaultButton>
            <DefaultButton onClick={reloadShifts} title="Reload">
                <FontAwesomeIcon icon={faArrowsRotate} />
            </DefaultButton>
        </div>

    )
}

export { DraftControl }