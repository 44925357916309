import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {Callout} from "@fluentui/react";
import {useBoolean} from "@fluentui/react-hooks";
import {ICharacteristic, IResource} from "../../../interfaces/resource";
import {ResourceLabelContentArg} from "@fullcalendar/resource-common";
import {resourcesViewContext} from "../../../providers/resourceView";

const InfoHover = (resource: ResourceLabelContentArg) => {
    const {open, close} = useContext(resourcesViewContext)
    const res = resource.resource.toPlainObject({collapseExtendedProps:true}) as IResource

    const handelClick = (e:React.MouseEvent<HTMLDivElement>)=>{
        open(e, res)
    }

    return (
        <div style={{position: 'absolute', top:5, right:5}}
             id={"buttonId"}
             onClick={handelClick}>
            <FontAwesomeIcon style={{color: '#525866', cursor: 'pointer'}} icon={faCircleInfo}/>
        </div>
    );
};


export default InfoHover;
