import { IAvantCareEnviromentInfo } from "../interfaces/resource";

const buildCrmUrl = (env: IAvantCareEnviromentInfo, entityName: string, id: string) => {

    if (env.resourceUrl.endsWith('/'))
        return `${env.resourceUrl}main.aspx?pagetype=entityrecord&etn=${entityName}&id=${id}`
    else
        return `${env.resourceUrl}/main.aspx?pagetype=entityrecord&etn=${entityName}&id=${id}`
}

export { buildCrmUrl }
