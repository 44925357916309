import {
    ComboBox,
    DatePicker,
    DefaultButton,
    DirectionalHint,
    IComboBox,
    IComboBoxOption,
    IconButton,
    Link,
    Modal,
    PrimaryButton,
    Spinner,
    Text,
    TextField,
    TimePicker,
    Toggle,
    useTheme
} from "@fluentui/react";
import {Dismiss24Regular} from "@fluentui/react-icons";
import React, {useContext, useEffect, useState} from "react";
import {EBookingStatus, ESystemStatus, IEvent} from "../../../interfaces/event";
import {EPublishStatus, shiftsContext} from "../../../providers/shifts";
import {resourcesContext} from "../../../providers/resources";
import {ILinkedError, IResource} from "../../../interfaces/resource";
import {differenceInMinutes, format} from "date-fns";
import {Table} from "./Table";
import {buildCrmUrl} from "../../../helpers/crmUrlBuilder";
import {apiContext} from "../../../providers/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faStopwatch, faTriangleExclamation, faXmark} from "@fortawesome/free-solid-svg-icons";
import {errorModalContext} from "./ErrorModal";
import {renderGenericFallbackUi} from "../../error-boundary/generic-fallback-ui";
import Swal from "sweetalert2";
import {AppInsightsErrorBoundary} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../../applicationInsights";

const Row = (props: { data: any, openErrorModal: (br: IResource, errors: ILinkedError[]) => void, assignEvent: (data: any) => void }) => (
    <tr>
        <td valign="top">{props.data.bookableResource.title}</td>
        <td valign="top"
            dangerouslySetInnerHTML={{__html: props.data.bookableResource.characteristics.map((x: any) => '- ' + x.name + (x.ratingName && x.ratingName != '' ? '(' + x.ratingName + ')' : '')).join('<br/>')}}/>
        <td valign="top">{props.data.bookableResource.hoursWorked} ({props.data.bookableResource.shifts})</td>
        <td>
            {
                props.data.errors && props.data.errors.length > 0 &&
                <div style={{cursor: 'pointer'}} title="Click to view errors" onClick={() => {
                    props.openErrorModal(props.data.bookableResource, props.data.errors)
                }}>
                    <div className="bidder-event-icon-container">
                        {props.data.unavailable &&
                            <span title="Unavailable" className={'bidder-event-icon'}>
                                    <FontAwesomeIcon icon={faXmark} color={'#f37037'}/>
                                </span>
                        }
                        {props.data.isOverTime &&
                            <span title="Overtime" className={'bidder-event-icon'}>
                                    <FontAwesomeIcon icon={faDollarSign} color={'#f37037'}/>
                                </span>
                        }
                        {props.data.exceededMaxWorkingHours &&
                            <span title="Exceeded maximum allowed hours" className={'bidder-event-icon'}>
                                    <FontAwesomeIcon icon={faStopwatch} color={'#f37037'}/>
                                </span>
                        }
                        {(!props.data.exceededMaxWorkingHours && !props.data.isOverTime && !props.data.unavailable && props.data.errors?.length > 0) &&
                            <span className={'bidder-event-icon'}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} color={'#f37037'}/>
                                </span>
                        }
                    </div>

                </div>
            }
        </td>
        <td valign="top">
            <PrimaryButton onClick={() => {
                props.assignEvent(props.data)
            }}> Assign & Close </PrimaryButton>
        </td>
    </tr>
)

const EventModal = ({
                        selectedShift,
                        setSelectedShift
                    }: { selectedShift?: string, setSelectedShift: (id: string | undefined) => void }) => {
    const {patch} = useContext(apiContext);
    const {getResource} = useContext(resourcesContext)
    const {
        getShift,
        updateShiftInfo,
        getResourceData,
        moveShift,
        getEnvironmentInfo,
        publishSingleShift,
        patchShift
    } = useContext(shiftsContext)

    const theme = useTheme()

    const [event, setEvent] = useState<IEvent | undefined>(undefined)
    const [preferredEmployee, setPreferredEmployee] = useState<IResource>()
    const [selectedResource, setSelectedResource] = useState<IResource>()
    const {openErrorModal} = useContext(errorModalContext)
    const [open, setOpen] = useState(false);
    const [awaitingPublish, setAwaitingPublish] = useState(false);
    const [awaitingSave, setAwaitingSave] = useState(false);
    const [openedDetails, setOpenedDetails] = useState(0);
    const [title, setTitle] = useState('');
    const [titleChangeTimeout, setTitleChangeTimeout] = useState(setTimeout(() => {
    }, 0))

    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (selectedShift) {
            openModal(selectedShift);
        }
    }, [selectedShift])

    useEffect(() => {
        if (!open) {
            setSelectedShift(undefined)
            clearState()
        }
    }, [open])

    const openModal = (id: string) => {
        const eventRequested = getShift(id);
        if (eventRequested) {
            setEvent(eventRequested)
            const resource = getResource(eventRequested.resourceId)
            if (resource) {
                setSelectedResource(resource)
            }
            if (eventRequested.resourceId !== eventRequested.preferredResourceId) {
                const preferred = getResource(eventRequested.preferredResourceId)
                if (preferred) {
                    setPreferredEmployee(preferred)
                }
            } else {
                if (resource) {
                    setPreferredEmployee(resource)
                }
            }

            setTitle(eventRequested.description)
            setOpen(true)
        }
    }

    const updateEvent = async (newData: Partial<IEvent>) => {
        if (!event) {
            return
        }

        setUpdated(true);

        setEvent((prev) => {
            return {
                loadingLock: true,
                ...prev,
                ...newData,
                detailsModified: true,
            } as IEvent
        })


        if (newData.isJobBidding !== null) {
            await patch('/Entity/msdyn_workorder/' + event.id, {
                illumina_openforshiftjobbidding: newData.isJobBidding
            })
        }

        setEvent((prev) => {
            if (prev) {
                return {
                    loadingLock: false,
                    ...prev,
                } as IEvent
            }
        })
    }

    const assignEvent = async (evt: any) => {
        if (!event || !event.id) {
            return
        }
        moveShift(evt.id, undefined, evt.bookableResource.id);
        updateShiftInfo(event.id, {...event, isJobBidding: false})
        setOpen(false);
    }

    const clearState = (newEvent?: Partial<IEvent>) => {
        if (awaitingPublish) return;

        if (event && updated) {
            updateShiftInfo(event.id, {...event, ...newEvent})
        }

        setEvent(undefined)
        setSelectedResource(undefined)
        setPreferredEmployee(undefined)
        setOpenedDetails(0)
        setTitle('');
        clearTimeout(titleChangeTimeout);
        setUpdated(false);
    }

    const getTimeAsString = (): string => {
        const time = differenceInMinutes(event?.end ?? new Date(), event?.start ?? new Date());
        if (time < 60) {
            return `${Math.round(time * 100) / 100} minute${time > 1 ? 's' : ''}`
        } else if (time < 1440) {
            return `${Math.round((time / 60) * 100) / 100} hour${(time / 60) > 1 ? 's' : ''}`
        } else {
            return `${Math.round((time / 1440) * 100) / 100} day${(time / 1440) > 1 ? 's' : ''}`
        }

    }

    const handlePublish = async () => {
        if (awaitingPublish) return;

        setAwaitingPublish(true);

        const response = await publishSingleShift(event);

        if (response.status === EPublishStatus.success) {
            if (event?.detailsModified) {
                await handleSaveShift();
            }
            setOpen(false);
        }

        setAwaitingPublish(false);
    }

    const handleChangeStart = (e: React.FormEvent<IComboBox>, time?: Date) => {
        if (time && event) {
            const newStart = new Date(event.start.getTime());
            newStart.setHours(time.getHours());
            newStart.setMinutes(time.getMinutes());

            const newEnd = new Date(newStart.getTime());
            newEnd.setMinutes(newEnd.getMinutes() + (event.shiftLength))

            const data: Partial<IEvent> = {
                start: newStart,
                end: newEnd,
            }
            updateEvent(data)
        }
    }

    const handleChangeDuration = (e: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event?.start && option) {
            const copiedDate = new Date(event?.start.getTime());

            copiedDate.setMinutes(copiedDate.getMinutes() + (option?.key as number))

            const data: Partial<IEvent> = {
                end: copiedDate,
                shiftLength: (option?.key as number)
            }
            updateEvent(data)
        }
    }

    const handleChangeDate = (date: Date | null | undefined) => {
        if (date) {
            const newStart = new Date(date.getTime());
            const newEnd = new Date(date.getTime());

            if (event) {
                newStart.setHours(event.start.getHours())
                newStart.setMinutes(event.start.getMinutes())

                newEnd.setHours(newStart.getHours() + event.shiftLength / 60);
            }

            const data: Partial<IEvent> = {
                start: newStart,
                end: newEnd,
            }

            updateEvent(data);

        }
    }

    const handleUpdateTitle = () => {
        clearTimeout(titleChangeTimeout);
        const data: Partial<IEvent> = {
            description: title,
        }

        updateEvent(data);
    }

    const handleChangeTitle = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value ?? '');
    }

    const handleTitleInputEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleUpdateTitle();
        }
    }

    useEffect(() => {
        if (title !== event?.description) {
            clearTimeout(titleChangeTimeout)
            setTitleChangeTimeout(setTimeout(handleUpdateTitle, 2000))
        }
    }, [title])

    const handleToggleDetails = (event: React.MouseEvent<HTMLElement>, value: number) => {
        event.preventDefault();
        const newOpenedDetails = openedDetails === value ? 0 : value;
        setOpenedDetails(newOpenedDetails);
    }

    const handleSaveShift = async () => {
        if (event) {
            setAwaitingSave(true)
            await patchShift(event?.id, event)
            setAwaitingSave(false)

            setUpdated(true);

            const newData = {
                ...event,
                detailsModified: false,
            };

            setEvent(newData);

            updateShiftInfo(event.id, newData)
        }
    }

    return (
        <>
            <Modal
                isOpen={open}
                onDismiss={() => setOpen(false)}
                isModeless={false}
                containerClassName={'eventModal'}
            >
                <AppInsightsErrorBoundary
                    onError={() => renderGenericFallbackUi({
                        mainHeading: 'Something went wrong whilst trying to load the shift',
                        subHeading: 'Please try again in a few minutes'
                    })}
                    appInsights={reactPlugin}
                >
                    <>
                        <div className={'modal-header'}>
                            <Text variant={'xxLarge'} block={true}>
                                {event ?
                                    <Link
                                        color={'black'}
                                        target={"_blank"}
                                        href={buildCrmUrl(getEnvironmentInfo(), 'msdyn_workorder', event.id)}
                                    >
                                        {event.description}
                                    </Link>

                                    : ""}
                            </Text>
                            <IconButton color={'#000000'} onClick={() => setOpen(false)}>
                                <Dismiss24Regular
                                    color={"#000000"}
                                />
                            </IconButton>
                        </div>

                        {event &&
                            <div className={'modal-content'}>
                                <div className={"info"}>
                                    <Text variant={'xLargePlus'} block>
                                        <strong>
                                            {format(event.start ?? new Date(), 'h:mm a')}
                                            &nbsp;-&nbsp;
                                            {format(event.end ?? new Date(), 'h:mm a')}
                                        </strong>
                                        <span>
                                            &nbsp;{"("}{getTimeAsString()}{")"}
                                        </span>
                                        <span>
                                            &nbsp;{"("}
                                            {format(event.start, 'dd/MM/yyyy') === format(event.end, 'dd/MM/yyyy')
                                                ? format(event.start, 'dd/MM/yyyy')
                                                : format(event.start, 'dd/MM/yyyy') + ' - ' + format(event.end, 'dd/MM/yyyy')
                                            }
                                            {")"}
                                        </span>
                                    </Text>
                                    <Text variant={'xLarge'} block>
                                        {event.title}
                                    </Text>
                                    <div className={"gap"}/>
                                    <Text variant={'xLarge'} block>
                                        <strong>Service Account:</strong> <Link target={"_blank"}
                                                                                href={buildCrmUrl(getEnvironmentInfo(), "account", event.serviceAccount.accountId)}>{event.serviceAccount.name}</Link>
                                    </Text>
                                    <div className={"gap"}/>
                                    {event.incidentType && event.incidentType.characteristic.length > 0 && <>
                                        <div style={{maxWidth: '400px'}}>
                                            <Text variant={'xLarge'} block>
                                                <strong>
                                                    Required Characteristics
                                                </strong>
                                            </Text>
                                            <Table
                                                content={event.incidentType.characteristic.map(char => (char.name))}/>
                                        </div>
                                    </>
                                    }
{/* 
                                    {(event.resourceId == null || event.resourceId == '' || event.resourceId == '0') &&
                                        <>
                                            <div style={{maxWidth: '100%'}}>
                                                <Text variant={'xLarge'} block>
                                                    <strong>
                                                        Bidders
                                                    </strong>
                                                </Text>
                                                <table className={"table"} cellPadding="5">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Skills/Characteristic</th>
                                                        <th>Assigned Hours (Shift)</th>
                                                        <th>Errors?</th>
                                                        <th>Assign?</th>
                                                    </tr>


                                                    {event.bidders.length > 0 ?
                                                        event.bidders.map((x, i) => {
                                                            const extendedEvent = event as any;
                                                            const br = getResource(x.bookableResourceId);
                                                            const rd = getResourceData(x.bookableResourceId);
                                                            const rr = {...br, ...rd}
                                                            extendedEvent.errors = x.errors;
                                                            extendedEvent.bookableResource = rr;
                                                            const extendedEvent1 = {...x, ...extendedEvent};
                                                            //x.event
                                                            return <Row key={i} data={extendedEvent1}
                                                                        assignEvent={assignEvent}
                                                                        openErrorModal={openErrorModal}/>
                                                        })
                                                        :
                                                        <tr style={{backgroundColor: '#f8f8f8', color: '#888'}}>
                                                            <th colSpan={5} style={{textAlign: 'center'}}>
                                                                There are currently no bidders
                                                            </th>
                                                        </tr>


                                                    }


                                                </table>
                                            </div>

                                        </>
                                    } */}


                                    {event.resourceId != '0' && event.resourceId != '' && event.errors && event.errors.length > 0 && <>
                                        <Text variant={'xLarge'} block>
                                            Validation Errors
                                        </Text>
                                        <Table content={event.errors}/>
                                    </>

                                    }
                                </div>
                                <div className={'options'}>
                                    <Text className={"text"} variant={'mediumPlus'} block>
                                        <strong>Type:</strong> {event.jobType}
                                    </Text>

                                    {preferredEmployee &&
                                        <Text className={"text"} variant={'mediumPlus'} block>
                                            <strong>Preferred Employee: </strong>
                                            {preferredEmployee.id === '' ? (
                                                preferredEmployee.title
                                            ) : (
                                                <Link
                                                    target={"_blank"}
                                                    href={buildCrmUrl(getEnvironmentInfo(), "bookableresource", preferredEmployee.id)}>
                                                    {preferredEmployee.title}
                                                </Link>
                                            )}
                                        </Text>
                                    }
                                    {selectedResource &&
                                        <Text className={"text"} variant={'mediumPlus'} block>
                                            <strong>Selected Employee: </strong>

                                            {event.resourceId === '' || event.resourceId === '0' ? (
                                                <span>{getResource(event.resourceId)?.title}</span>
                                            ) : (
                                                <Link
                                                    target={"_blank"}
                                                    href={buildCrmUrl(getEnvironmentInfo(), "bookableresource", event.resourceId)}
                                                >
                                                    <span>{getResource(event.resourceId)?.title}</span>
                                                </Link>
                                            )}
                                        </Text>
                                    }
                                    <Text className={"text"} variant={'mediumPlus'}><strong>Actions:</strong></Text>

                                    {/* {
                                        (event.resourceId !== '0') ? "" :
                                            <Toggle label="Job Bidding" onText="Active" offText="Inactive"
                                                    checked={event.isJobBidding} onClick={() => {
                                                updateEvent({isJobBidding: !event.isJobBidding})
                                            }}/>
                                    } */}

                                    <details className="details-box" open={openedDetails === 1}>
                                        <summary onClick={(event) => handleToggleDetails(event, 1)}>Modify Date and
                                            Time
                                        </summary>
                                        <DatePicker
                                            label="Date:"
                                            value={event.start}
                                            onSelectDate={handleChangeDate}
                                        />
                                        <TimePicker
                                            label="Start Time:"
                                            useHour12
                                            increments={15}
                                            defaultValue={event.start}
                                            onChange={handleChangeStart}
                                            calloutProps={{calloutMinWidth: 120, calloutMaxHeight: 200}}

                                        />
                                        <ComboBox
                                            label="Duration:"
                                            onChange={handleChangeDuration}
                                            selectedKey={event.shiftLength}
                                            calloutProps={{
                                                calloutMinWidth: 120,
                                                calloutMaxHeight: 200,
                                                directionalHint: DirectionalHint.bottomRightEdge
                                            }}
                                            options={[
                                                {key: 1, text: '1 minute'},
                                                {key: 15, text: '15 minutes'},
                                                {key: 30, text: '30 minutes'},
                                                {key: 45, text: '45 minutes'},
                                                {key: 60, text: '1 hour'},
                                                {key: 90, text: '1.5 hours'},
                                                {key: 120, text: '2 hours'},
                                                {key: 150, text: '2.5 hours'},
                                                {key: 180, text: '3 hours'},
                                                {key: 210, text: '3.5 hours'},
                                                {key: 240, text: '4 hours'},
                                                {key: 270, text: '4.5 hours'},
                                                {key: 300, text: '5 hours'},
                                                {key: 330, text: '5.5 hours'},
                                                {key: 360, text: '6 hours'},
                                                {key: 390, text: '6.5 hours'},
                                                {key: 420, text: '7 hours'},
                                                {key: 450, text: '7.5 hours'},
                                                {key: 480, text: '8 hours'},
                                                {key: 1440, text: '1 day'},
                                                {key: 2880, text: '2 days'},
                                                {key: 4320, text: '3 days'},
                                            ]}
                                        />
                                    </details>

                                    <details className="details-box" open={openedDetails === 2}>
                                        <summary onClick={(event) => handleToggleDetails(event, 2)}>Modify Shift
                                            Details
                                        </summary>
                                        <TextField
                                            label="Title:"
                                            value={title}
                                            onChange={handleChangeTitle}
                                            onBlur={handleUpdateTitle}
                                            onKeyDown={handleTitleInputEnter}
                                        />
                                        <DefaultButton
                                            className={'shift-cancel-button'}
                                            disabled={event.bookableResourceStatus === EBookingStatus.Cancelled}
                                            onClick={async () => {
                                                const confirmation = await Swal.fire({
                                                    title: 'Are you sure you want to cancel this shift?',
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: theme.palette.themePrimary,
                                                    cancelButtonColor: theme.palette.tealLight,
                                                    confirmButtonText: 'Yes',
                                                    cancelButtonText: 'No',

                                                })
                                                if (confirmation.isConfirmed) {
                                                    await patchShift(event.id, {status: ESystemStatus.Canceled})

                                                    setOpen(false);
                                                }
                                            }}
                                        >
                                            {event.bookableResourceStatus === EBookingStatus.Cancelled ? 'Cancelled' : 'Cancel Shift'}
                                        </DefaultButton>
                                    </details>

                                </div>

                            </div>

                        }
                        <div className={'button-modal-container'}>

                            <PrimaryButton disabled={!(event?.detailsModified) || awaitingSave}
                                           onClick={handleSaveShift}>{awaitingSave ?
                                <Spinner/> : 'Save'}</PrimaryButton>
                            <PrimaryButton disabled={event?.changeStatus === 0}
                                           onClick={handlePublish}>{awaitingPublish ?
                                <Spinner/> : `Publish${event?.detailsModified && event?.changeStatus !== 0 ? '/Save' : ''}`}</PrimaryButton>
                            <DefaultButton onClick={() => setOpen(false)}
                                           disabled={awaitingPublish}>Cancel</DefaultButton>

                        </div>
                    </>
                </AppInsightsErrorBoundary>
            </Modal>
        </>

    )

}

export default EventModal
