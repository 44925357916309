import Swal, {SweetAlertIcon} from "sweetalert2";

const toast = (icon:SweetAlertIcon, title:string)=>Swal.fire({
    toast:true,
    icon:icon,
    title:title,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export {toast}