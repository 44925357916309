import { EEventStatus } from "../helpers/calendar";
import { ChangeStatus } from "./event";

interface ICharacteristic {
   name: string;
   id: string;
   ratingName: string;
}

interface IAvailability {
   resourceId: string,
   availability: EEventStatus
}

interface IResource {
   id: string;
   title: string;
   index: number;
   personalNote?: string;
   profileImageUrl?: string;
   minimumWorkHoursPerPayroll?: number;
   maximumWorkHourPerPayroll?: number;
   characteristics: ICharacteristic[];
   calendarId?: string;
   availabilities?: IExpandCalendarAvailabity[];
   siteLocation?: ISiteLocation[];
   maxHoursPerShift?: number;
}

interface IResourceIngress {
   bookableresourceid: string
   name: string;
   minimumWorkHoursPerPayroll: number;
   illumina_maximumworkhourperpayroll?: number;
   illumina_minimumworkhourperpayroll?: number;

   UserId: {
      firstname: string,
      lastname: string
      photoUrl?: string
   }
   illumina_resourcesummary: string
   bookableresource_bookableresourcecharacteristic_Resource: ICharacteristicIngress[]
   calendarid: string
   contactid: string
   _calendarid_value: string;
   illumina_BookableResource_illumina_sitelocation_il: ISiteLocation[];
   illumina_maximumhourspershift: number;
}

interface ICharacteristicIngress {
   bookableresourcecharacteristicid: string
   name: string;
   '_ratingvalue_value@OData.Community.Display.V1.FormattedValue': string;
}


interface IDVBookableResourceBooking { //DV stands for DataVerse
   _bookingstatus_value: string;
   '_bookingstatus_value@OData.Community.Display.V1.FormattedValue': string;
   _resource_value: string;
   bookableresourcebookingid: string;
}

interface IWorkOrder {
   msdyn_timewindowstart: string;
   msdyn_timewindowend: string;
   msdyn_workorderid: string;
   msdyn_workordersummary: string;
   msdyn_systemstatus: number;
   msdyn_name: string;
   msdyn_timefrompromised: string;
   msdyn_timetopromised: string;
   _msdyn_preferredresource_value: string;
   '_msdyn_preferredresource_value@OData.Community.Display.V1.FormattedValue': string;
   'illumina_jobtype@OData.Community.Display.V1.FormattedValue': string;
   msdyn_serviceaccount: any; //TODO: Use strongly typed
   illumina_openforshiftjobbidding: boolean;
   msdyn_primaryincidenttype: any;//TODO: Use strongly typed
   msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder: Array<IDVBookableResourceBooking>;
   msdyn_totalestimatedduration: number
}

interface IOtherSiteWorkOrder {
   msdyn_timewindowstart: string;
   msdyn_timewindowend: string;
   msdyn_name: string;
   msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder: Array<IDVBookableResourceBooking>;
   msdyn_totalestimatedduration: number
   msdyn_timefrompromised: string;
   msdyn_timetopromised: string;
   msdyn_workorderid: string;
}

interface IExpandCalendarAvailabity {
   start: Date;
   end: Date;
   timeCode: string;
}
interface IExpandCalendarResponse {
   calendarId: string;
   availabilities: IExpandCalendarAvailabity[];
}

interface IRosteringBookingViewModel {
   id: string;
   jobId: string;
   tempId: string;
   start: Date;
   end: Date;
   bookableResourceId: string;
   cost: number;
   status: ChangeStatus;
   bookingId: string;
}

interface IServiceResponse<T> {
   body: T;
   success: boolean;
   errors: string[];
}
interface ILinkedError {
   message: string;
   referenceId: string;
   title: string;
   entityName: string;
   errorType: RosterValidationErrorType;
}
interface IRosterValidationResponse {
   status: string;
   errors: ILinkedError[]
}
interface IBookableResourceRosterValidationResponse {
   bookableResourceId: string;
   id: string;
   errors: ILinkedError[];
}
enum RosterValidationErrorType {
   Unknown = 'Unknown',
   OverTime = 'OverTime',
   MissingSkills = 'MissingSkills',
   ConfiltBooking = 'ConfiltBooking',
   Unavailable = 'Unavailable',
   ExceededMaxWorkingHours = 'ExceededMaxWorkingHours',
   SaveFailed = 'SaveFailed'
}
interface IAvantCareEnviromentInfo {
   oDataUrl: string;
   resourceUrl: string;
}

interface ISiteLocation {
   illumina_name: string
   illumina_sitelocationid: string
}

interface IPublishShiftsRequestViewModel {
   siteId: string;
   saveShiftsWarning: boolean;
   shifts: IRosteringBookingViewModel[];
}

interface IDraftSavedItem {
   tenantId: string;
   userId: string;
   siteId: string;
   title: string;
   createdOn: string;
   id: string;
   start: string;
   end: string;
}
interface IResourceData {
   maxHoursAllowedPerPayroll: number;
   maxShiftsAllowedPerPayroll: number;
   hoursWorked: number;
   allSitesHoursWorked: number;
   shifts: number,
   allSitesShifts: number,
   requirements: [],
   totalPay: number;
   minimumWorkHoursPerPayroll: number;
   maxHoursPerShift: number;
}
interface IPayrollHourVariationResponse {
   _illumina_payrollcycle_value: string;
   illumina_maximumnoofworkhoursperpayroll: number;
   illumina_minimumnoofworkhoursperpayroll: number;
   _illumina_bookableresource_value: string;
   illumina_payrollcycle: {
      illumina_enddate: string;
      illumina_startdate: string;
   }
}
interface IPayrollHourVariation {
   payrollCycleId: string;
   maximumHoursPerPayroll: number;
   minimumHoursPerPayroll: number;
   bookableResourceId: string;
   payrollStart: Date;
   payrollEnd: Date;
}



export { RosterValidationErrorType };
export type {
   IResource, ILinkedError, IResourceIngress, ICharacteristic, IAvailability, IDVBookableResourceBooking, IWorkOrder, IOtherSiteWorkOrder, IExpandCalendarResponse, IExpandCalendarAvailabity, IRosteringBookingViewModel, IServiceResponse, IRosterValidationResponse,
   IBookableResourceRosterValidationResponse, IAvantCareEnviromentInfo, ISiteLocation, IPublishShiftsRequestViewModel, IDraftSavedItem, IResourceData,
   IPayrollHourVariationResponse,IPayrollHourVariation
}