import { DirectionalHint, FontIcon, IconButton, ITooltipHostStyles, ITooltipProps, TooltipHost, useTheme } from "@fluentui/react"
import { useId, useConst } from '@fluentui/react-hooks';
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from './assets/legend.module.scss'

const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
        <>
            <div className={styles.legendContent}>
                <h4>Border Styles</h4>
                <p className={styles.draft}>Draft</p>
                <p className={styles.published}>Published</p>
                <br />
                <h4>Border Colours</h4>
                <p className={styles.noIssues}>
                    No Issues
                </p>
                <p className={styles.softAlert}>Soft Alerts</p>
                <p className={styles.hardAlert}>Hard Alerts</p>
                <p className={styles.unassigned}>Unassigned</p>
                <br />
                <h4>Icons</h4>
                <p><FontIcon iconName="Clock" style={{color: '--color-warningIcon'}} /> Overtime</p>
                <p><FontIcon iconName="Library" style={{color: '--color-warningIcon'}} /> Missing Skills</p>
                <p><FontIcon iconName="Warning" style={{color: '--color-errorIcon'}} /> Resource not available</p>
                <p><FontAwesomeIcon icon={faBell} color={'#f37037'} /> Flagged for job bidding</p>
            </div>
        </>
    ),
};



const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const Legend = () => {
    const theme = useTheme();
    const tooltipId = useId('legend');
    const buttonId = useId('targetButton');

    const calloutProps = useConst({
        // If the tooltip should point to an absolutely-positioned element,
        // you must manually specify the callout target.
        target: `#${buttonId}`,
        gapSpace: 5,
    });

    return (
        <>
            <TooltipHost
                id={tooltipId}
                tooltipProps={tooltipProps}
                calloutProps={calloutProps}
                directionalHint={DirectionalHint.topRightEdge}
            >
                <IconButton
                    aria-describedby={tooltipId}
                    iconProps={{ iconName: 'Help', className: styles.legendIcon }}
                    disabled={false}
                    className={styles.legend}
                    id={buttonId}
                    style={{ backgroundColor: theme.palette.themePrimary, color: theme.palette.white }}
                />
            </TooltipHost>
        </>
    )
}

export default Legend
