import FullCalendar, {DateSpanApi, EventApi, RefObject} from "@fullcalendar/react";
import {IAvailability} from "../interfaces/resource";

const eventAllowed = (dateSpan: DateSpanApi,
                      checkShiftLocationIsValid: (start: Date, end: Date, eventId?: string, resourceId?: string, originalResourceId?: string) => EEventStatus,
                      calendarRef: RefObject<FullCalendar>,
                      event: EventApi | null,
                      defineUserAvailability: (dateStart: Date, dateEnd: Date, resourceID: string, eventId: string) => IAvailability[]
): boolean => {
    const originalResource = event?.getResources()[0]
    if (!originalResource) {
        return false
    }
    const availability = defineUserAvailability(dateSpan.start, dateSpan.end, originalResource.id, event?.id)
    const resourceView: HTMLCollection = document.getElementsByClassName('fc-resource')
    const calender = document.getElementById('calender')
    if (calender) {
        for (let i = 0; i <= resourceView.length; i++) {
            const resource = resourceView.item(i) as HTMLDivElement
            if (!resource) {
                continue
            }
            const currentResource = resource.dataset.resourceId
            if (currentResource === '0') {
                // resource.style.background = 'white'
                continue
            }
            const cAvail = availability.find((av) => av.resourceId === currentResource)
            let color = "transparent"
            if (cAvail) {
                color = setMarkerColor(cAvail.availability)
            }
            resource.style.background = color
        }
    }

    const allowed = checkShiftLocationIsValid(dateSpan.start, dateSpan.end, event?.id, dateSpan.resource?.id, originalResource.id)
    return allowed !== 2
    // return true
}


const eventDragStop = (calendarRef: RefObject<FullCalendar>) => {
    const resourceView: HTMLCollection = document.getElementsByClassName('fc-resource')
    for (let i = 0; i <= resourceView.length; i++) {
        const res = resourceView[i] as HTMLDivElement
        if (res) {
            if (res.dataset.resourceId !== '0') {
                res.style.background = 'white'
            }

        }
    }
}

const setMarkerColor = (allowed: EEventStatus): string => {
    switch (allowed) {
        case EEventStatus.error:
            return 'rgba(255,142,142,0.3)'
        case EEventStatus.valid:
            return 'rgba(242,242,242,0.3)'
        case EEventStatus.characteristicsIssue:
            return 'rgba(245,172,24,0.3)'
    }
}

export enum EEventStatus {
    valid,
    characteristicsIssue,
    error
}

export {eventAllowed, eventDragStop}