import {
    ChangeStatus,
    EBookingStatus,
    ECalendarStatus,
    ESystemStatus,
    IBookableResourceBooking,
    IEvent,
    IOtherSiteEvent
} from "../../interfaces/event";
import { IIncidentType } from "../../interfaces/incedentTypes";
import { ICharacteristic, IOtherSiteWorkOrder, IWorkOrder } from "../../interfaces/resource";
import addMinutes from "date-fns/addMinutes";

const shiftsFormatter = (apiShift: IWorkOrder, incidentTypes: IIncidentType[]): IEvent | undefined => {
    if(apiShift.msdyn_systemstatus === ESystemStatus.Canceled){
        return;
    }

    const bidders = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() == 'expressed interest'); //Expressed interest is the display name - id can't be used as it might change in prod
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"] != 'Canceled');
    const event: Partial<IEvent> = {}

    if (assignedWorkBookings.length > 0) {
        const workOrder = assignedWorkBookings[0];
        event.resourceId = workOrder['_resource_value']
        event.originalResourceID = workOrder['_resource_value']
        event.bookableResourceStatus = workOrder['_bookingstatus_value'] as any;
        event.BookingId = workOrder.bookableresourcebookingid;
    } else {
        event.resourceId = '0'
        event.originalResourceID = '0'
        event.bookableResourceStatus = EBookingStatus.Scheduled
        event.BookingId = '';
    }

    const setCalendarStatus = (): ECalendarStatus => {
        if (event.resourceId === '0') {
            return ECalendarStatus.scheduled
        }
        return ECalendarStatus.assigned
    }

    const primaryIncidentType = () => {
        if (apiShift.msdyn_primaryincidenttype && apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid) {
            return incidentTypes.find(type => {
                return type.id === apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid
            })
        }
        return {
            name: 'Not Required',
            id: '0',
            characteristic: []
        }
    }
    const sd = new Date(apiShift.msdyn_timewindowstart);
    const ed = apiShift.msdyn_totalestimatedduration ? addMinutes(sd, apiShift.msdyn_totalestimatedduration) : new Date(apiShift.msdyn_timewindowend); // if duration exists, use duration. Otherwise use enddate

    return {
        title: apiShift.msdyn_name,
        start: sd,
        end: ed,
        id: apiShift.msdyn_workorderid,
        description: apiShift.msdyn_workordersummary?apiShift.msdyn_workordersummary:apiShift.msdyn_name,
        status: apiShift.msdyn_systemstatus,
        preferredResourceId: apiShift._msdyn_preferredresource_value,
        preferredResourceName: apiShift['_msdyn_preferredresource_value@OData.Community.Display.V1.FormattedValue'],
        jobType: apiShift['illumina_jobtype@OData.Community.Display.V1.FormattedValue'],
        incidentType: primaryIncidentType(),
        bidders: bidders.map(x => <IBookableResourceBooking>{
            bookingStatus: x._bookingstatus_value,
            id: x.bookableresourcebookingid,
            bookableResourceId: x._resource_value
        }),
        calendarStatus: setCalendarStatus(),
        serviceAccount: {
            name: apiShift.msdyn_serviceaccount.name,
            accountId: apiShift.msdyn_serviceaccount.accountid,
            accountNumber: apiShift.msdyn_serviceaccount.accountnumber
        },
        isJobBidding: apiShift.illumina_openforshiftjobbidding,
        changeStatus: ChangeStatus.UnChanged,
        shiftLength: apiShift.msdyn_totalestimatedduration,
        loading: false,
        ...event
    } as IEvent
}

const otherSiteShiftFormatter = (apiShift: IOtherSiteWorkOrder): IOtherSiteEvent | undefined => {
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"] != 'Canceled');

    const sd = new Date(apiShift.msdyn_timefrompromised);
    const ed = apiShift.msdyn_totalestimatedduration
        ? addMinutes(sd, apiShift.msdyn_totalestimatedduration)
        : new Date(apiShift.msdyn_timewindowend);

    if (assignedWorkBookings.length > 0) {
        const event: IOtherSiteEvent = {
            id: apiShift.msdyn_workorderid,
            editable: false,
            resourceEditable: false,
            startEditable: false,
            otherSite: true,
            resourceId: assignedWorkBookings[0]._resource_value,
            title: apiShift.msdyn_name,
            start: sd,
            end: ed,
            shiftLength: apiShift.msdyn_totalestimatedduration
        }

        return event;
    }


}

export { shiftsFormatter, otherSiteShiftFormatter }