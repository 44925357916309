import { useContext, useEffect, useState } from "react";
import { IIncidentType } from "../interfaces/incedentTypes";
import { apiContext } from "../providers/api";
import { ICharacteristic } from "../interfaces/resource";


const useIncidentTypes = (initialState: IIncidentType[] = []): [IIncidentType[], () => Promise<IIncidentType[]>] => {

    const [incidentTypes, setIncidentTypes] = useState<IIncidentType[]>(initialState)
    const { get } = useContext(apiContext)

    // useEffect(() => {
    //     reloadIncidentTypes()
    // }, [])

    const reloadIncidentTypes = async () => {

        const incidentTypes = await get(
            '/Entity/msdyn_incidenttype',
            {
                expand: 'msdyn_msdyn_incidenttype_msdyn_incidenttypecharacteristic_IncidentType'
            }
        )
        if(incidentTypes && incidentTypes.value ){
            const inciTypesFormatted = incidentTypes.value.map(formatIncidentData)
            setIncidentTypes(inciTypesFormatted)
            return inciTypesFormatted
        }
        return []
    }

    return [incidentTypes, reloadIncidentTypes]
}



const formatIncidentData = (apiIncident: any): IIncidentType => {
    const characteristic = apiIncident['msdyn_msdyn_incidenttype_msdyn_incidenttypecharacteristic_IncidentType'].map((char: any): ICharacteristic => (
        {
            name: char['_msdyn_characteristic_value@OData.Community.Display.V1.FormattedValue'],
            id: char['_msdyn_characteristic_value'],
            ratingName: ''
        }
    ))
    return {
        name: apiIncident.msdyn_name,
        id: apiIncident.msdyn_incidenttypeid,
        characteristic
    }

}

export { useIncidentTypes }